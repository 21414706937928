import Navbar from './Components/1_Navbar';
import HomeBanner from './Components/2_HomeBanner';
import Infobanner from './Components/3_InfoBanner';
import Countbanner from './Components/4_CountBanner';
import ProductSection from './Components/5_ProductSection';
import TechSection from './Components/6_TechSection';
import EcommerceBanner from './Components/7_Ecommerce';
import UsSection from './Components/8_Us';
import Footer from './Components/9_Footer';
import Institucional from './Components/Subcategorie/6_Institutional';
import Display from './Components/Subcategorie/3_Display';
import Packaging from './Components/Subcategorie/4_Packaging';
import Labels from './Components/Subcategorie/5_Labels';
import Visual from './Components/Subcategorie/2_Visual';
import Brand from './Components/Subcategorie/1_Brand_experience';
import Exterior from './Components/Subcategorie/Exterior/1_Exterior';
import Pop from './Components/Subcategorie/Pop/1_Pop';
import Mailer from './Components/10_Mailer'
import BannerApp from './Components/11_BannerApp'
import Testimonies from './Components/12_testimonies';
import Trusted from './Components/13_Trusted';
import ResentProjects from './Components/14_ResentProjects';
import Gif from './Components/15_Gif_area';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import { Routes, Route, HashRouter } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div>
      <HashRouter>
        <Navbar />
        <Main />
        <Footer />
      </HashRouter>
    </div>
  );
}

const Main = () => (
  <main id="scrollWrapper" className="main">
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/index.html" element={<HomePage />} />
      <Route path="*" element={<HomePage />} />
      <Route path="display" element={<Display />} />
      <Route path="packaging" element={<Packaging />} />
      <Route path="labels" element={<Labels />} />
      <Route path="institutional" element={<Institucional />} />
      <Route path="visual" element={<Visual />} />
      <Route path="exterior" element={<Exterior />} />
      <Route path="pop" element={<Pop />} />
      <Route path="brand" element={<Brand />} />
      <Route path="AvisoPrivacidad" element={<PrivacyPolicy />} />
    </Routes>
  </main>
);

const HomePage = () => {
  return (
    <div>
      <HomeBanner />
      {/* <Infobanner /> */} {/* antigua seccion se deja para futuras secciones */}
      {/* <ProductSection /> */}
      <Mailer />
      <TechSection />
      <Countbanner />
      <Trusted/>
      <Testimonies/>
      {/* <BannerApp/> */}
      <EcommerceBanner />
      <ResentProjects />
      <UsSection />
      <Gif />
    </div>
  );
};

export default App;
